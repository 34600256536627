import Vue from "vue"

const { app_current_locale } = window
import localLangs from "@public-modal/shared/langs"

import VueScreenSize from "vue-screen-size"

Vue.use(VueScreenSize)

export default {
    methods: {
        $t(key) {
            if (localLangs[app_current_locale]) {
                return localLangs[app_current_locale][key] || key
            }
            return localLangs["en"][key] || key
        },
        $hasAttr(attr) {
            if (this.$attrs.hasOwnProperty(attr)) {
                if (this.$attrs[attr] === "") {
                    return attr
                } else if (typeof this.$attrs[attr] === "boolean") {
                    if (!this.$attrs[attr]) {
                        return ""
                    } else {
                        return attr
                    }
                }
            }
            return ""
        }
    },
    data() {
        return {
            appCurrentLocale: app_current_locale
        }
    },
    computed: {
        $screenWidth() {
            return this.$vssWidth
        }
    },
    watch: {
        $screenWidth() {
            this.$forceUpdate()
        }
    }
}
